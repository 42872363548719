<template>
  <Dialog v-if="visible" v-model="visible" :options="dialogOptions.dialog">
    <v-form
      v-if="Object.keys(info).length"
      ref="form"
      style="display: flex; align-items: center; position: relative"
    >
      <v-row style="flex: 1; margin: 0">
        <template v-for="e of panel">
          <v-col
            v-if="e.visible ? e.visible(info) : true"
            :key="e.field"
            cols="12"
          >
            <span>{{ e.text }}：</span>
            <span class="breakAll" :style="e.style ? e.style(info) : {}">{{
                e.format ? e.format(info) : info[e.field] ? info[e.field] : '-'
              }}</span>
          </v-col>
        </template>
      </v-row>
      <iframe
        v-if="info.type == '02' && videoUrl"
        ref="video"
        :src="`${$businessApi}/monitor/video/video-play/live?videoUrl=${formatVideoUrl}`"
        width="960px"
        height="540px"
        frameborder="0"
      ></iframe>
      <div
        style='
          width: 960px;
          height: 540px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          position: relative;
        '
      >
        <div class="attach-filter" v-if="info.attachList && info.attachList.length">
            <span @click="attachActive = index" :class="{active:index === attachActive}"
                  v-for="(index) in info.attachList.length"
                  :key="index">{{ index }}</span>
        </div>
        <img
          v-if='
            !info.attachList ||
            !info.attachList[0] ||
            isImage(info.attachList[attachActive-1])
          '
          v-lazy='`${$imgUrl}${info.attachList && info.attachList[attachActive-1]}`'
          :style="`height: 100%;flex: 1; object-fit: contain; overflow: hidden;cursor:${
            isImage(info.attachList && info.attachList[attachActive-1]) ? 'pointer' : 'auto'
          }`"
          alt='违规'
          @click="
            info.attachList && info.attachList[attachActive-1]
              ? $bus.$emit('ImgDialog', {
                  imgUrl: `${$imgUrl}${info.attachList[attachActive-1]}`,
                })
              : null
          "
        />
        <template v-else>

          <video
            :src='`${$imgUrl}${info.attachList[attachActive-1]}`'
            controls
            style='flex: 1; object-fit: contain; overflow: hidden;height:100%'
          ></video>
        </template>
      </div>
    </v-form>
  </Dialog>
</template>

<script>
import exception from '@/utils/exception';
import {assiginDeep, isImage} from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';

export default {
  //组件注册
  components: {
    Dialog,
  },
  //组件传值
  props: {},
  data() {
    return {
      attachActive:1,
      videoUrl: undefined,
      info: {},
      visible: false,
      dialogOptions: {
        dialog: {
          width: 1320,
          title: '任务详情',
        },
      },
      panel: [
        {
          text: '网点当前人数',
          field: 'innerNum',
          visible: (info) => ['02'].includes(info.type),
        },
        {
          text: '操作位置',
          field: 'cameraName',
          visible: (info) => ['01', '03', '04'].includes(info.type),
        },
        {
          text: '任务名称',
          field: 'taskName',
          visible: (info) => ['01', '03'].includes(info.type),
        },
        {
          text: '任务类型',
          field: 'analysisItemText',
          visible: (info) => ['01', '03'].includes(info.type),
        },
        {
          text: '检测类型',
          field: 'analysisItemText',
          visible: (info) => ['04'].includes(info.type),
        },
        {
          text: '任务编号',
          field: 'taskNo',
          visible: (info) => ['01', '03'].includes(info.type),
        },
        {
          text: '提醒次数',
          field: 'times',
          format: (info) => `第${info.times ? info.times : 0}次`,
          visible: (info) => ['01'].includes(info.type),
        },
        {
          text: '执行状态',
          field: 'taskStatusText',
          visible: (info) => ['01', '03'].includes(info.type),
        },
        {
          text: '计划完成时间',
          field: 'planCompleteTime',
          visible: (info) => ['01', '03'].includes(info.type),
        },
        {
          text: '处罚登记',
          field: 'remark',
          visible: (info) => ['03'].includes(info.type),
        },
        {
          text: '预警时间',
          field: 'createdTime',
          visible: (info) => ['04'].includes(info.type),
        },
        {
          text: '执行人',
          field: 'operatorName',
          visible: (info) => ['04'].includes(info.type),
        },
      ],
    };
  },
  //计算属性
  computed: {
    formatVideoUrl() {
      return this.videoUrl
        ? encodeURIComponent(this.videoUrl)
        : 'https://rtmp01open.ys7.com:9188/v3/openlive/D27662088_1_1.flv?expire=1684839549&id=450734794958737408&t=185cc3a46e138d203e3235172035fd48b835a473e54e1eef879d161166d48d31&ev=100';
    },
  },
  //属性监听
  watch: {
    visible() {
      if (this.visible) {
        this.info = {};
      }
    },
  },
  //DOM访问
  mounted() {
  },
  //保存方法
  methods: {
    isImage,
    async getCamera(cameraUUID) {
      try {
        const data = await this.$http.get(
          `/${this.$businessISG}/monitor/hall/video-view`,
          {
            cameraUUID,
          }
        );
        if (data.code != 200) throw data.message;
        const [first] = data.data;
        if (!first) throw '数据格式错误！';
        this.videoUrl = first;
      } catch (error) {
        this.videoUrl = undefined;
        this.kalert({
          type: 'error',
          content: `${error}`,
        });
      }
    },
    openDialog(options = {}) {
      this.dialogOptions = assiginDeep(this.dialogOptions, {...options});
      this.info = options.row;
      if (this.info.uuid) this.getInfo();
    },
    async getInfo() {
      try {
        const data = await this.$http.get(
          `/${this.$businessISG}/monitor/home/warn-info/${this.info.uuid}`
        );
        if (data.code != 200) throw data.message;
        const [first] = data.data;
        if (!first) throw Error('数据格式错误！');
        this.visible = true;
        if (first.readFlag == '02') {
          this.$emit('read');
          this.$bus.$emit('read-warn');
        }
        if (first.type == '02' && first.cameraUUID) {
          this.getCamera(first.cameraUUID);
        }
        this.$nextTick(() => {
          this.dialogOptions = assiginDeep(this.dialogOptions, {
            dialog: {
              title: `指示详情${first.remark ? `-${first.remark}` : ''}`,
            },
          });
          this.info = first;
        });
      } catch (error) {
        exception.toastError(error + '');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  font-size: 16px;
  font-weight: 400;
  color: #d2e2e7;
}

.breakAll {
  word-break: break-all;
}

.attach-filter {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;

  span {
    padding: 0 10px;
    background: #eeeeee;
    color: #000;
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
    border: 1px solid #DCDFE6;
    font-size: 16px;

    &.active {
      color: #fff;
      background: #909399;
    }
  }
}
</style>

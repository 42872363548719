<template>
  <Dialog v-if='visible' v-model='visible' :options='dialogOptions.dialog'>
    <v-form
      v-if='Object.keys(info).length'
      ref='form'
      style='display: flex; align-items: center; position: relative'
    >
      <v-row style='flex: 1; margin: 0'>
        <template v-for='e of panel'>
          <v-col
            v-if='e.visible ? e.visible(info) : true'
            :key='e.field'
            cols='12'
          >
            <span>{{ e.text }}：</span>
            <span :style='e.style ? e.style(info) : {}' class='breakAll'>{{
                e.format
                  ? e.format(info[e.field])
                  : info[e.field]
                  ? info[e.field]
                  : '-'
              }}</span>
          </v-col>
        </template>
        <template v-if='!isInfo'>
          <v-col cols='12'>
            <span
            >处理建议：
              <Select
                v-model='form.handleRemark'
                :allow-clear='false'
                :options='warnType'
                :replace-fields="{
                  lable: 'msg',
                  value: 'msg',
                }"
                container-style='display: inline-flex;min-width: 120px;'
                placeholder='请选择处理建议'
              ></Select>
            </span>
            <v-text-field
              v-model='form.handleRemark'
              :counter='200'
              :rules="[
                (v) => !!v || '请输入处理建议！',
                (v) => (v && v.length <= 200) || '最大输入200字符！',
              ]"
              placeholder='请输入处理建议'
              solo
              style='margin-top: 8px'
            >
            </v-text-field>
          </v-col>
          <v-col cols='12' style='cursor: pointer'>
            <img
              alt='确定'
              height='40'
              src='@/assets/screen/bj/btn_confirm.png'
              style='margin-right: 15px'
              width='90'
              @click='onSubmit'
            />
          </v-col>
        </template>
      </v-row>
      <div v-if='!isInfo'>
        <Select
          v-model='form.cameraUUID'
          :allow-clear='false'
          :options='info.cameraList'
          :replace-fields="{
            lable: 'cameraName',
            value: 'uuid',
          }"
          container-style='display: inline-flex;'
          placeholder='请选择摄像头'
        ></Select>
        <div
          style='
            width: 960px;
            height: 540px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin-top: 8px;
          '
        >
          <iframe
            ref='video'
            :src='`${$businessApi}/monitor/video/video-play/live?videoUrl=${formatVideoUrl}`'
            frameborder='0'
            height='100%'
            width='100%'
          ></iframe>
        </div>
      </div>
      <div v-else style='display: flex; align-items: center'>
        <div
          style='
            width: 920px;
            height: 620px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin-top: 8px;
          '
        >
          <iframe
            ref='video'
            :src='`${$businessApi}/monitor/video/video-play/live?videoUrl=${formatVideoUrl}`'
            frameborder='0'
            height='100%'
            width='100%'
          ></iframe>
        </div>
      </div>
      <v-overlay :value='overlay' absolute>
        <v-progress-circular indeterminate size='64'></v-progress-circular>
        <div>数据提交中...</div>
      </v-overlay>
    </v-form>
  </Dialog>
</template>

<script>
import exception from '@/utils/exception';
import { mapState } from 'vuex';
import { assiginDeep, isImage } from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';
import Select from '@/views/screen/components/Select.vue';

export default {
  //组件注册
  components: {
    Dialog,
    Select,
  },
  //组件传值
  props: {
    prefix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      videoUrl: undefined,
      current: 0,
      form: {
        cameraUUID: undefined,
        remark: undefined,
      },
      overlay: false,
      panel: [
        {
          text: '机构',
          field: 'text',
          visible: (info) => info.cameraList,
        },
        {
          text: '机构',
          field: 'organizationText',
          visible: (info) => !info.cameraList,
        },
        {
          text: '当前网点人数',
          field: 'count',
          format: (value) => `${value ? value : 0}人`,
          visible: (info) => info.cameraList,
        },
        {
          text: '当前网点人数',
          field: 'innerNum',
          format: (value) => `${value ? value : 0}人`,
          visible: (info) => !info.cameraList,
        },
        {
          text: '预警时间',
          field: 'createdTime',
          visible: (info) => !info.cameraList,
        },
        {
          text: '当前提醒次数',
          field: 'times',
          format: (value) => `第${value ? value : 0}次`,
          visible: (info) => !info.cameraList,
        },
        {
          text: '处理建议',
          field: 'remark',
          visible: (info) => !info.cameraList,
        },
        {
          text: '操作人',
          field: 'createdByName',
          visible: (info) => !info.cameraList,
        },
      ],
      info: {},
      visible: false,
      dialogOptions: {
        dialog: {
          width: 1320,
          title: '网点人数预警',
        },
      },
    };
  },
  //计算属性
  computed: {
    ...mapState({
      warnType: (state) =>
        state.screenStore.commondict['OrganizationWarnTypeEnum']
          ? state.screenStore.commondict['OrganizationWarnTypeEnum']
          : [],
    }),
    formatVideoUrl() {
      return this.videoUrl
        ? encodeURIComponent(this.videoUrl)
        : 'https://rtmp01open.ys7.com:9188/v3/openlive/D27662088_1_1.flv?expire=1684839549&id=450734794958737408&t=185cc3a46e138d203e3235172035fd48b835a473e54e1eef879d161166d48d31&ev=100';
    },
    isInfo() {
      return !this.info.cameraList;
    },
    isSubtract() {
      return this.current > 0;
    },
    isAdd() {
      return (
        this.current <
        (this.info.attachList ? this.info.attachList.length - 1 : 0)
      );
    },
  },
  //属性监听
  watch: {
    visible() {
      if (this.visible) {
        this.info = {};
        this.form = {
          cameraUUID: undefined,
          remark: undefined,
        };
        this.overlay = false;
      }
    },
    'form.cameraUUID': {
      handler: 'getCamera',
    },
  },
  //DOM访问
  mounted() {
  },
  //保存方法
  methods: {
    isImage,
    openDialog(options = {}) {
      this.dialogOptions = assiginDeep(this.dialogOptions, { ...options });
      this.visible = true;
      this.$nextTick(() => {
        if (options.row) {
          this.info = options.row;
          if (this.info.cameraList) {
            this.form.organizationUUID = options.row.code;
            if (this.info.cameraList.length) {
              this.info.cameraList = this.info.cameraList.filter(v => v.organizationUuid === this.info.code && v.areaCode === 'lobby');
              console.log(this.info.cameraList);
              this.form.cameraUUID = this.info.cameraList[0] ? this.info.cameraList[0].uuid : '';
            }
          } else if (options.row.cameraUUID) {
            this.form.cameraUUID = options.row.cameraUUID;
          }
          console.log(this.form);
        }
      });
    },
    async getCamera(cameraUUID) {
      if (!cameraUUID) return
      try {
        const data = await this.$http.get(
          `/${this.$businessISG}/monitor/hall/video-view`,
          {
            cameraUUID,
          },
        );
        if (data.code != 200) throw data.message;
        const [first] = data.data;
        if (!first) throw '数据格式错误！';
        this.videoUrl = first;
      } catch (error) {
        this.videoUrl = undefined;
        this.kalert({
          type: 'error',
          content: `${error}`,
        });
      }
    },
    async onSubmit() {
      try {
        if (this.$refs.form && !this.$refs.form.validate()) return;
        this.overlay = true;
        const data = await this.$http.post(
          `/${this.$businessISG}/monitor/home/organization-warn`,
          this.form,
          {
            'Content-Type': 'application/json;charset=UTF-8',
          },
        );
        if (data.code != 200) throw data.message;
        this.overlay = false;
        exception.toastInfo(data.message);
        this.visible = false;
        this.$emit('success');
      } catch (error) {
        exception.toastError(error + '');
        this.overlay = false;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.col {
  font-size: 16px;
  font-weight: 400;
  color: #d2e2e7;
}

.breakAll {
  word-break: break-all;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('Dialog',{attrs:{"options":_vm.dialogOptions.dialog},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(Object.keys(_vm.info).length)?_c('v-form',{ref:"form",staticStyle:{"display":"flex","align-items":"center","position":"relative"}},[_c('v-row',{staticStyle:{"flex":"1","margin":"0"}},[_vm._l((_vm.panel),function(e){return [(e.visible ? e.visible(_vm.info) : true)?_c('v-col',{key:e.field,attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(e.text)+"：")]),_c('span',{staticClass:"breakAll",style:(e.style ? e.style(_vm.info) : {})},[_vm._v(_vm._s(e.format ? e.format(_vm.info[e.field]) : _vm.info[e.field] ? _vm.info[e.field] : '-'))])]):_vm._e()]}),(!_vm.isInfo)?[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("处理建议： "),_c('Select',{attrs:{"allow-clear":false,"options":_vm.warnType,"replace-fields":{
                lable: 'msg',
                value: 'msg',
              },"container-style":"display: inline-flex;min-width: 120px;","placeholder":"请选择处理建议"},model:{value:(_vm.form.handleRemark),callback:function ($$v) {_vm.$set(_vm.form, "handleRemark", $$v)},expression:"form.handleRemark"}})],1),_c('v-text-field',{staticStyle:{"margin-top":"8px"},attrs:{"counter":200,"rules":[
              function (v) { return !!v || '请输入处理建议！'; },
              function (v) { return (v && v.length <= 200) || '最大输入200字符！'; } ],"placeholder":"请输入处理建议","solo":""},model:{value:(_vm.form.handleRemark),callback:function ($$v) {_vm.$set(_vm.form, "handleRemark", $$v)},expression:"form.handleRemark"}})],1),_c('v-col',{staticStyle:{"cursor":"pointer"},attrs:{"cols":"12"}},[_c('img',{staticStyle:{"margin-right":"15px"},attrs:{"alt":"确定","height":"40","src":require("@/assets/screen/bj/btn_confirm.png"),"width":"90"},on:{"click":_vm.onSubmit}})])]:_vm._e()],2),(!_vm.isInfo)?_c('div',[_c('Select',{attrs:{"allow-clear":false,"options":_vm.info.cameraList,"replace-fields":{
          lable: 'cameraName',
          value: 'uuid',
        },"container-style":"display: inline-flex;","placeholder":"请选择摄像头"},model:{value:(_vm.form.cameraUUID),callback:function ($$v) {_vm.$set(_vm.form, "cameraUUID", $$v)},expression:"form.cameraUUID"}}),_c('div',{staticStyle:{"width":"960px","height":"540px","display":"flex","align-items":"center","justify-content":"center","overflow":"hidden","margin-top":"8px"}},[_c('iframe',{ref:"video",attrs:{"src":(_vm.$businessApi + "/monitor/video/video-play/live?videoUrl=" + _vm.formatVideoUrl),"frameborder":"0","height":"100%","width":"100%"}})])],1):_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"width":"920px","height":"620px","display":"flex","align-items":"center","justify-content":"center","overflow":"hidden","margin-top":"8px"}},[_c('iframe',{ref:"video",attrs:{"src":(_vm.$businessApi + "/monitor/video/video-play/live?videoUrl=" + _vm.formatVideoUrl),"frameborder":"0","height":"100%","width":"100%"}})])]),_c('v-overlay',{attrs:{"value":_vm.overlay,"absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}}),_c('div',[_vm._v("数据提交中...")])],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }